.rename__input-group {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 5px;
  margin-bottom: 10px;
}

.rename__input {
  color: #c5c5c5;
  padding: 5px;
  width: 100%;
  border: none;
  border: solid 1px #232323;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  background-color: #161616;
}

.rename__input-short {
  width: 20%;
}

.rename__input:hover {
  border: solid 1px #3456f3;
  outline: none;
}

.rename__input:focus {
  border: solid 1px #3456f3;
  outline: none;
}

.hidden {
  display: none;
}
